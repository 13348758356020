import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#f6f6f7',
    color100: '#ededee',
    color200: '#dbdcdd',
    color300: '#c9cacc',
    color400: '#96989b',
    color500: '#63656a',
    color600: '#4f5155',
    color700: '#3b3d40',
    color800: '#28282a',
    color900: '#141415'
  },
  default: {
    color50: '#DDF3F3',
    color100: '#BCE7E6',
    color200: '#9ADBDA',
    color300: '#78CECD',
    color400: '#57C2C1',
    color500: '#206D6C',
    color600: '#2A9290',
    color700: '#206D6C',
    color800: '#154948',
    color900: '#0B2424'
  },
  accent: {
    color50: '#fffad9',
    color100: '#fff5b3',
    color200: '#fff08d',
    color300: '#ffea66',
    color400: '#ffe540',
    color500: '#ffe01a',
    color600: '#ccb315',
    color700: '#998610',
    color800: '#665a0a',
    color900: '#332d05'
  },
  positive: {
    color50: '#E5F3E4',
    color100: '#CBE6C8',
    color200: '#B1DAAD',
    color300: '#97CE92',
    color400: '#7DC176',
    color500: '#3B6D37',
    color600: '#4F9149',
    color700: '#3B6D37',
    color800: '#284824',
    color900: '#142412'
  },
  negative: {
    color50: '#FAD5D8',
    color100: '#F6ABB0',
    color200: '#F18289',
    color300: '#EC5862',
    color400: '#E82E3A',
    color500: '#E30413',
    color600: '#B6030F',
    color700: '#88020B',
    color800: '#5B0208',
    color900: '#2D0104'
  },
  special: {
    color50: '#E6E6E6',
    color100: '#CCCCCC',
    color200: '#999999',
    color300: '#666666',
    color400: '#333333',
    color500: '#000000',
    color600: '#000000',
    color700: '#000000',
    color800: '#000000',
    color900: '#000000'
  }
};

const variant: ACPVariant = {
  id: 'www.wuallaccess.com',
  productTitle: 'Western Union All-Access',
  productType: 'consumer_dda',
  accessDomain: {
    subdomain: 'www',
    domain: 'wuallaccess',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'wu-all-access',
  legacyAcpBrandName: 'westernunionaa',
  legacyLegosTheme: 'wu-all-access',
  status_bar_background_color: '#000000',
  status_bar_foreground_color: 'light',
  themeConfiguration: {
    acpRisingTideLayout: {
      appBarDefaultBackgroundColor: colors.special.color500,
      notificationCounterBackgroundColor: colors.default.color500
    },
    illustration: {
      default: { ...colors.special },
      special: { ...colors.default }
    }
  },
  contactInfo: {
    name: 'Western Union All access',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@wuallaccesss.com',
    fax_formatted: '(512) 857-0263',
    phone_formatted: '(888) 963-8773',
    phone: '8889638773',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '2d8c6147-f9d2-45e3-9b49-7049bc7d9cb2',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: true,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: false,
      showScanCardRegistration: true,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: true,
      showReloadLocationLink: false
    },
    misc: {
      contactRecipient: 'Pathward, National Association, c/o Netspend',
      layoutComponentOverride: false,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#0092BC',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#84bd00',
      marketingSiteUrl: 'https://www.wuallaccess.com',
      marketingSiteLinkText: 'wuallaccess.com',
      locationSearchMax: '25'
    },
    content: {},
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'dda-activation',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
